import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import FeatureCardsSection from '../components/featureCardsSection';
import TechBox from '../components/techBox';

import CloudIcon from '../../assets/svg/cloud_m.svg';
import NginxIcon from '../../assets/svg/nginx-icon.svg';
import PrometheusIcon from '../../assets/svg/prometheus.svg';
import KubernetesIcon from '../../assets/svg/kubernetes-opt.svg';
import DockerIcon from '../../assets/svg/docker-plain.svg';
import GithubIcon from '../../assets/svg/github-original.svg';
import TerraformIcon from '../../assets/svg/terraform.svg';
import DevOpsConcept from '../../assets/svg/devops-loop.svg';
import ServerIcon from '../../assets/svg/server.svg';
import CICDIcon from '../../assets/svg/cicd.svg';
import MonitoringIcon from '../../assets/svg/monitoring.svg';
import SEO from '../components/seo';

const DevOpsPage = () => {
  const data = useStaticQuery(graphql`
        query DevOpsImage {
            kompleksowaDO: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Usługi
          {' '}
          <span>DevOps</span>
        </>
              )}
      subtitle="Między produkcją oprogramownia a administracją"
      img="/img/serwery-devops.jpg"
    >
      <SEO title="Usługi DevOps i Sysadmin - Kubernetes w iqcode Software House" description="Jako software house świadczymy usługi DevOps związane z administracją serwerami, wdrożeniami środowiska Kubernetes oraz ciągłą integracją i wdrażaniem." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Usługi
              {' '}
              <span>DevOps</span>
              {' '}
              i Sysadmin
            </>
                      )}
          img={<DevOpsConcept style={{ width: '95%', height: 'auto', margin: '0 auto' }} />}
          desc={(
            <>
              <p>
                DevOps to metodyka zakładająca ścisłą współpracę zespołów
                tworzących oprogramowanie (
                <i>development</i>
                ) oraz
                zespołów odpowiedzialnych za jego utrzymanie i administrację
                (
                <i>operations</i>
                ).
              </p>
              <p>
                Jako software house świadczymy usługi DevOps związane z
                utrzymaniem aplikacji w środowiskach
                produkcyjnych ze spełnieniem rygorystycznych
                {' '}
                <strong>warunków SLA</strong>
                {' '}
                określających
                wymagania dostępności, wydajności oraz
                niezawodności oprogramowania.
              </p>
              <p>
                Nasze rozwiązania
                DevOps w znakomitej większości opieramy o
                nowoczesne środowisko systemów rozproszonych
                {' '}
                <strong>Kubernetes</strong>
                ,
                które pozwala na osiągnięcie wysokiej dostępności aplikacji
                przy jednoczesnym ograniczeniu kosztów infrastruktury oraz
                administracji.
              </p>
            </>
                      )}
          bottom={(
            <TechBox
              fullWidth
              technologies={[
                {
                  icon: <ServerIcon />,
                  name: 'Administracja Serwerami',
                },
                {
                  icon: <CICDIcon />,
                  name: 'Ciągła Integracja i Wdrażanie',
                },
                {
                  icon: <CloudIcon />,
                  name: 'Migracja do Chmury',
                },
                {
                  icon: <MonitoringIcon />,
                  name: 'Monitoring Aplikacji',
                },
              ]}
            />
                    )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Chcesz wycenić
              {' '}
              <span>Projekt</span>
              ?
            </h3>
            <Link className="btn" to="/kontakt/">
              Porozmawiajmy
            </Link>
          </div>
        </div>
        <FeatureCardsSection
          id="backend"
          title={(
            <>
              Technologie
              {' '}
              <span>DevOps</span>
            </>
                      )}
          subtitle="Najważniejsze technologie DevOps, które stosujemy"
          cards={[
            {
              title: 'Kubernetes',
              desc:
                                'Środowisko systemów rozproszonych umożliwiające uruchamianie konteneryzowanych aplikacji z zapewnieniem odporności na awarie poszczególnych węzłów klastra.',
              icon: <KubernetesIcon />,
            },
            {
              title: 'Docker',
              desc:
                                'Ekosystem wirtualizacji służący do opakowywania aplikacji w przenośne kontenery dla uproszczenia procesu instalacji i konfiguracji oprogramowania.',
              icon: <DockerIcon />,
            },
            {
              title: 'Prometheus',
              desc:
                                'Nowoczesne narzędzie monitoringu aplikacji uruchomionych w środowisku rozproszonym umożliwiające zbieranie i analizę ich parametrów działania.',
              icon: <PrometheusIcon />,
            },
            {
              title: 'Nginx',
              desc:
                                'Otwartoźródłowy serwer WWW oraz serwer proxy dla aplikacji internetowych wykorzystywany jako kontroler ruchu przychodzącego w klastrze Kubernetes.',
              icon: <NginxIcon />,
            },
            {
              title: 'GitHub',
              desc:
                                'Nowoczesna platforma hostingowa repozytoriów kodu źródłowego umożliwiająca tworzenie środowisk ciągłej integracji i wdrażania.',
              icon: <GithubIcon />,
            },
            {
              title: 'Terraform',
              desc:
                                'Narzędzie pozwalające na automatyzację procesu dzierżawienia i konfiguracji infrastruktury definiowanej deklaratywnie jako kod.',
              icon: <TerraformIcon />,
            },
          ]}
        />
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Potrzebujesz doradztwa
              {' '}
              <span>projektowego</span>
              ?
            </h3>
            <Link className="btn" to="/consulting-it/">
              Consulting IT
            </Link>
          </div>
        </div>
        <FeatureSectionLight
          title={(
            <>
              <span>Kompleksowa </span>
              Realizacja Projektów IT
            </>
                      )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="kompleksowe usługi devops"
                fluid={data.kompleksowaDO.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                      )}
          desc={(
            <>
              <p>
                Poza usługami DevOps świadczonymi w związku z
                utrzymaniem tworzonych przez nas aplikacji,
                implementujemy środowiska ciągłej integracji i
                wdrażania dla zespołów programistycznych innych
                producentów oprogramowania, przeprowadzamy
                migracje infrastruktury
                {' '}
                <i>on-premise</i>
                {' '}
                do
                {' '}
                <strong>chmury publicznej</strong>
                {' '}
                oraz świadczymy usługi z
                zakresu
                {' '}
                <strong>administracji serwerami Linux</strong>
                .
              </p>
              <p>
                Usługi związane z utrzymaniem oprogramowania na
                infrastrukturze serwerowej to nie jedyny obszar
                naszej specjalizacji. Oferujemy kompleksową
                realizację projektów informatycznych począwszy
                od projektowania oprogramowania i inżynierii
                wymagań, poprzez prace programistyczne i
                doradztwo projektowe związane z debiutem
                rynkowym aplikacji, a zakończywszy na wsparciu
                technicznym, analityce i ciągłym rozwoju
                produktu cyfrowego.
              </p>
            </>
                      )}
          link="Porozmawiajmy"
          to="/kontakt/"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default DevOpsPage;
